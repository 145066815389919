
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import ns1c0f4d2329c02b40cca9a292726ee736en_US from './en-US.yaml'
            i18n.addResourceBundle('en-US', '1c0f4d2329c02b40cca9a292726ee736', flattenObject(ns1c0f4d2329c02b40cca9a292726ee736en_US), true, true);
        
            import ns1c0f4d2329c02b40cca9a292726ee736en from './en.yaml'
            i18n.addResourceBundle('en', '1c0f4d2329c02b40cca9a292726ee736', flattenObject(ns1c0f4d2329c02b40cca9a292726ee736en), true, true);
        
        export default '1c0f4d2329c02b40cca9a292726ee736';
    