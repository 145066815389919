
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import ns80efda4d20957482acd10062f060aea1en_US from './en-US.yaml'
            i18n.addResourceBundle('en-US', '80efda4d20957482acd10062f060aea1', flattenObject(ns80efda4d20957482acd10062f060aea1en_US), true, true);
        
            import ns80efda4d20957482acd10062f060aea1en from './en.yaml'
            i18n.addResourceBundle('en', '80efda4d20957482acd10062f060aea1', flattenObject(ns80efda4d20957482acd10062f060aea1en), true, true);
        
        export default '80efda4d20957482acd10062f060aea1';
    