// @flow
import * as React from 'react'
import { withTranslation, Trans } from 'react-i18next'
import withTheme from 'hoc/withTheme'
import MediaQuery from 'react-responsive'
import Color from 'color'
import RegistrationForm from 'components/RegistrationForm'

import styles from './styles.module.scss'
import supportedThemes from './themes/__supportedThemes.js'

import transDomain from './translations/index.translations'

import MainLogo from '../MainLogo'
import { Store } from 'Store'

import type { Theme } from './themes/__supportedThemes'

type Props = {
    theme: Theme
}

function Hero (props: Props) {
    const { theme } = props
    const { state } = React.useContext(Store)

    const bgOverlay = Color(theme.colors.primary).mix(Color('#000'), 0.6).fade(0.8)

    return (
        <div className={styles.mainContainer}>

            <div className={styles.bgWrapper} style={{ backgroundImage: `url(${theme.background})` }}>

                <div className={styles.container}>
                    <div className={styles.logoContainer}>
                        <MainLogo title={state.campaign.client.name} />
                    </div>

                    <div className={styles.registerForm} style={{ background: bgOverlay }}>
                        <div className={styles.formContainer}>
                            <h3>
                                <Trans i18nKey="title">
                                    <strong style={{ color: theme.titleColor }}>Our Truck Month Upgrade Sale is Back!</strong>
                                </Trans>
                            </h3>
                            <RegistrationForm/>
                        </div>
                    </div>

                    <div className={styles.mask} style={{ backgroundImage: `url(${theme.mask})` }} />
                </div>
                <div className="register-cars-masked-layers js-register-cars-masked-layers">
                    <div className="register-cars-layer js-masked-layer">
                        <MediaQuery minWidth={768}>
                            <img src={theme.carsNew} alt="" />
                        </MediaQuery>
                        <MediaQuery maxWidth={767}>
                            <img src={theme.carsNewMobile} alt="" />
                        </MediaQuery>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(Hero))
